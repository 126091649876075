import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as _ from 'lodash'

// import { useGetAllNHLGamesQuery, useGetNHLRawGameDataQuery } from "../../services"
import { useState } from 'react';
import { VALID_SPORTS } from '../../models';

import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { DataExplorerGameData, GameData, SUB_PAGE } from '../../models/common';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { Button, InputAdornment, TextField } from '@mui/material';
import { mapSportToApiKey, mapSubPageToApiKey } from '.';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export interface DataExplorerProps {
  sport: VALID_SPORTS,
  getAllGamesQuery: UseQuery<QueryDefinition<{marketKey: string, sportKey: string, includePrices: boolean, forceCache?: boolean}, any, never, GameData[], string>>,
  getRawGameDataQuery: UseQuery<QueryDefinition<{gameId: string, marketKey: string, sportKey: string}, any, never, DataExplorerGameData, string>>
}

const parseResponseToRow = (game: GameData) => {
  const homeTeamData = game.teams[game.homeTeam]
  const awayTeamData = game.teams[game.awayTeam]
  const date = new Date(game.startTime)

  return {
    id: game.id,
    homeTeam: game.homeTeam,
    awayTeam: game.awayTeam,
    startTime: date,
    homeScore: homeTeamData.score,
    awayScore: awayTeamData.score,
    homeMoneylineResult: homeTeamData.h2h?.result,
    homeMoneylineOpen: homeTeamData.h2h?.price.open || '∅',
    homeMoneylineCurrent: homeTeamData.h2h?.price.current || '∅',
    homeMoneylineClose: homeTeamData.h2h?.price.close || '∅',
    homeMoneylineBest: homeTeamData.h2h?.price.best || '∅',
    awayMoneylineResult: awayTeamData.h2h?.result,
    awayMoneylineOpen: awayTeamData.h2h?.price.open || '∅',
    awayMoneylineCurrent: awayTeamData.h2h?.price.current || '∅',
    awayMoneylineClose: awayTeamData.h2h?.price.close || '∅',
    awayMoneylineBest: awayTeamData.h2h?.price.best || '∅',
    //@ts-ignore
    outcomePointHome: game.outcome_point_home,
    homeRunlineResult: homeTeamData.spreads?.result,
    homeRunlineOpen: homeTeamData.spreads?.price.open || '∅',
    homeRunlineCurrent: homeTeamData.spreads?.price.current || '∅',
    homeRunlineClose: homeTeamData.spreads?.price.close || '∅',
    homeRunlineBest: homeTeamData.spreads?.price.best || '∅',
    //@ts-ignore
    outcomePointAway: game.outcome_point_away,
    awayRunlineResult: awayTeamData.spreads?.result,
    awayRunlineOpen: awayTeamData.spreads?.price.open || '∅',
    awayRunlineCurrent: awayTeamData.spreads?.price.current || '∅',
    awayRunlineClose: awayTeamData.spreads?.price.close || '∅',
    awayRunlineBest: awayTeamData.spreads?.price.best || '∅',
    //@ts-ignore
    totalSpread: game.total_spread,
    homeTotalResult: homeTeamData.totals?.result,
    homeTotalOpen: homeTeamData.totals?.price.open || '∅',
    homeTotalCurrent: homeTeamData.totals?.price.current || '∅',
    homeTotalClose: homeTeamData.totals?.price.close || '∅',
    homeTotalBest: homeTeamData.totals?.price.best || '∅',
    awayTotalResult: awayTeamData.totals?.result,
    awayTotalOpen: awayTeamData.totals?.price.open || '∅',
    awayTotalCurrent: awayTeamData.totals?.price.current || '∅',
    awayTotalClose: awayTeamData.totals?.price.close || '∅',
    awayTotalBest: awayTeamData.totals?.price.best || '∅',
    //@ts-ignore
    duplicateGame: game.duplicate_game || ''
  }
}

const GameDataExplorer = (props: {sport: VALID_SPORTS, subPage: SUB_PAGE, gameId: string, handleClose: ()=> void, getRawGameDataQuery: UseQuery<any>}) => {
  const {sport, subPage, gameId, handleClose, getRawGameDataQuery} = props

  const { 
    data: gameData, 
    error: gameError, 
    isLoading: gameLoading, 
  } = getRawGameDataQuery({
    sportKey: mapSportToApiKey(sport),
    marketKey: mapSubPageToApiKey(subPage),
    gameId: gameId
  })

  return <div className='w-100 h-100' style={{backgroundColor: 'rgba(0, 0, 0, 0.7)'}} onClick={handleClose}>
    {
      gameLoading ? <div className='d-flex h-100 justify-content-center align-items-center' style={{fontSize: '50px'}}>Loading...</div>
      : gameError ? <div className='d-flex justify-content-center align-items-center'>Error</div>
      : <div className='d-flex flex-column mt-5 justify-content-center align-items-center'>
        <textarea 
          readOnly={true}
          className='w-60 h-95'
          rows={30}
          cols={60}
          value={JSON.stringify(gameData, null, 4)}
          style={{width: '75%', minHeight: '500px', overflowY: 'scroll'}}
          onClick={(evt) => {evt.stopPropagation()}}
        />
        <button onClick={handleClose} style={{width: '100px'}}>Close</button>
      </div>
    }
  </div>
}

export const DataExplorer = (props: DataExplorerProps) => {
  const {sport, getAllGamesQuery, getRawGameDataQuery} = props
  const [showGameModal, setShowGameModal] = useState<string | null>(null)
  const [searchText, setSearchText] = useState<string>('')
  const [subPage, setSubPage] = useState<SUB_PAGE>(SUB_PAGE.MONEYLINE)

  const { 
    data: allGamesData, 
    error: allGamesError, 
    isLoading: allGamesLoading 
  } = getAllGamesQuery({
    sportKey: mapSportToApiKey(sport),
    marketKey: mapSubPageToApiKey(subPage),
    includePrices: true,
    forceCache: true
  })

  const renderWinLossPush = (params: GridRenderCellParams) => {
    return (
      params.value === 'Win' ? <span style={{color: 'lime'}}>Win</span>
      : params.value === 'Loss' ? <span style={{color: 'red'}}>Loss</span>
      : params.value
    )
  }

  const renderNull = (params: GridRenderCellParams) => {
    if (params.value === '∅') {
      return <span style={{color: 'gray', margin: '0 3px'}}>∅</span>
    } else {
      return params.value
    }
  }

  const rowData = allGamesData?.filter((game) => {
    if (searchText !== '') {
      return game.homeTeam.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || 
        game.awayTeam.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
        game.id.includes(searchText.toLocaleLowerCase())
    }

    return true
  }).map(parseResponseToRow) || []

  const renderDateCell = (params: GridRenderCellParams<any, Date>) => {
    const date = params.value as Date

    return `${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`
  }

  const baseCols: GridColDef[] = [
    {field: 'id', headerName: 'Game Id'},
    {field: 'startTime', headerName: `Start Time (${Intl.DateTimeFormat().resolvedOptions().timeZone})`, width: 120, type: 'date', renderCell: renderDateCell},
    {field: 'homeTeam', headerName: 'Home Team', width: 140},
    {field: 'awayTeam', headerName: 'Away Team', width: 140},
    {field: 'homeScore', headerName: 'Home Score', width: 50},
    {field: 'awayScore', headerName: 'Away Score', width: 50},
  ]
  const moneylineCols = [
    {field: 'homeMoneylineResult', headerName: 'Home Moneyline Result', renderCell: renderWinLossPush, width: 60},
    {field: 'homeMoneylineOpen', headerName: 'Home Open', renderCell: renderNull, width: 60},
    {field: 'homeMoneylineCurrent', headerName: 'Home Current', renderCell: renderNull, width: 60},
    {field: 'homeMoneylineClose', headerName: 'Home Close', renderCell: renderNull, width: 60},
    {field: 'homeMoneylineBest', headerName: 'Home Best', renderCell: renderNull, width: 60},
    {field: 'awayMoneylineResult', headerName: 'Away Moneyline Result', renderCell: renderWinLossPush, width: 60},
    {field: 'awayMoneylineOpen', headerName: 'Away Open', renderCell: renderNull, width: 60},
    {field: 'awayMoneylineCurrent', headerName: 'Away Current', renderCell: renderNull, width: 60},
    {field: 'awayMoneylineClose', headerName: 'Away Close', renderCell: renderNull, width: 60},
    {field: 'awayMoneylineBest', headerName: 'Away Best', renderCell: renderNull, width: 60},
  ]
  const spreadCols = [
    {field: 'outcomePointHome', headerName: 'Outcome Point Home', width: 50},
    {field: 'homeRunlineResult', headerName: 'Home Spread Result', renderCell: renderWinLossPush, width: 60},
    {field: 'homeRunlineOpen', headerName: 'Home Open', renderCell: renderNull, width: 60},
    {field: 'homeRunlineCurrent', headerName: 'Home Current', renderCell: renderNull, width: 60},
    {field: 'homeRunlineClose', headerName: 'Home Close', renderCell: renderNull, width: 60},
    {field: 'homeRunlineBest', headerName: 'Home Best', renderCell: renderNull, width: 60},
    {field: 'outcomePointAway', headerName: 'Outcome Point Away', width: 50},
    {field: 'awayRunlineResult', headerName: 'Away Spread result', renderCell: renderWinLossPush, width: 60},
    {field: 'awayRunlineOpen', headerName: 'Away Open', renderCell: renderNull, width: 60},
    {field: 'awayRunlineCurrent', headerName: 'Away Current', renderCell: renderNull, width: 60},
    {field: 'awayRunlineClose', headerName: 'Away Close', renderCell: renderNull, width: 60},
    {field: 'awayRunlineBest', headerName: 'Away Best', renderCell: renderNull, width: 60},
  ]
  const totalCols = [
    {field: 'totalSpread', headerName: 'Total Spread', width: 50},
    {field: 'homeTotalResult', headerName: 'Home Total Result', renderCell: renderWinLossPush, width: 60},
    {field: 'homeTotalOpen', headerName: 'Home Open', renderCell: renderNull, width: 60},
    {field: 'homeTotalCurrent', headerName: 'Home Current', renderCell: renderNull, width: 60},
    {field: 'homeTotalClose', headerName: 'Home Close', renderCell: renderNull, width: 60},
    {field: 'homeTotalBest', headerName: 'Home Best', renderCell: renderNull, width: 60},
    {field: 'awayTotalResult', headerName: 'Away Total Result', renderCell: renderWinLossPush, width: 60},
    {field: 'awayTotalOpen', headerName: 'Away Open', renderCell: renderNull, width: 60},
    {field: 'awayTotalCurrent', headerName: 'Away Current', renderCell: renderNull, width: 60},
    {field: 'awayTotalClose', headerName: 'Away Close', renderCell: renderNull, width: 60},
    {field: 'awayTotalBest', headerName: 'Away Best', renderCell: renderNull, width: 60},
  ]

  const columns = [...baseCols]
  if (subPage === SUB_PAGE.MONEYLINE) {
    columns.push(...moneylineCols)
  } else if (subPage === SUB_PAGE.SPREAD) {
    columns.push(...spreadCols)
  } else if (subPage === SUB_PAGE.TOTAL) {
    columns.push(...totalCols)
  }

  const handleClickRow = ( params: GridRowParams ) => {
    setShowGameModal(params.row.id)
  }

  const handleDownloadAll = async () => {
    const allColumns = [
      ...baseCols,
      ...moneylineCols,
      ...spreadCols,
      ...totalCols
    ]

    // Ideally we'd use the rtk-query for this, but this is a workaround to get alex all the data in 1 file quickly
    const baseUrl = process.env.NODE_ENV === 'production' ? 'https://wager-trends-http.azurewebsites.net/api' : process.env.PUBLIC_URL + '/api'
    const [h2h, spreads, totals]: {[id: string]: GameData}[] = await Promise.all([
      fetch(`${baseUrl}/games?includeBetData=true&includePrices=true&sportKey=${mapSportToApiKey(sport)}&marketKey=h2h`).then(res => res.json()),
      fetch(`${baseUrl}/games?includeBetData=true&includePrices=true&sportKey=${mapSportToApiKey(sport)}&marketKey=spreads`).then(res => res.json()),
      fetch(`${baseUrl}/games?includeBetData=true&includePrices=true&sportKey=${mapSportToApiKey(sport)}&marketKey=totals`).then(res => res.json()),
    ]).then(output => 
      // This converts the arrays to id-indexed objects
      output.map((subpageData: GameData[]) => subpageData.reduce((acc,val) => {
        acc[val.id] = val
        return acc
      }, {} as {[id: string]: GameData}))
    )

    const combinedOutput = Object.values(_.merge(h2h, spreads, totals)).map(parseResponseToRow)

    let csvContent = 'data:text/csv;charset=utf-8,'
    csvContent += '\n"'
    csvContent += Object.keys(combinedOutput[0]).map(key => {
      if (key === 'startTime') {
        return 'Start Time'
      }

      return allColumns.find(col => col.field === key)?.headerName || key
    }).join('","')
    csvContent += '"\n'

    combinedOutput.forEach((row: {[id: string]: any}) => {
      csvContent += '"'
      csvContent += Object.values(row)
        .map(val => {
          if (val?.getTime && val.getTime()) {
            return val.toISOString()
          }
          return val
        })
        .join('","')
        .replaceAll("∅", "")
      csvContent += '"\n'
    })

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", `${sport}_Table_Data.csv`)
    document.body.appendChild(link)

    link.click()
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <h1 className='m-3'>{sport} {subPage} Raw Data Explorer</h1>
      <div>
        {[SUB_PAGE.MONEYLINE, SUB_PAGE.SPREAD, SUB_PAGE.TOTAL].map(mapSubPage => (
          <Button
            onClick={() => {setSubPage(mapSubPage)}}
            variant={subPage === mapSubPage ? 'contained' : 'outlined'}
          >
            {mapSubPage}
          </Button>
        ))}
      </div>
      <DataGrid 
        // className='w-100 h-100'
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25
            }
          }
        }}
        rowHeight={26}
        rows={rowData}
        columns={columns}
        onRowClick={handleClickRow}
        loading={allGamesLoading}
        disableRowSelectionOnClick
        style={{fontSize: "11px", minHeight: '300px'}}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: 1
          }
        }}
      />

      <div className='w-100 d-flex flex-row-reverse justify-content-between my-2 px-3'>
        <TextField 
          style={{minWidth: '300px'}}
          placeholder='Search by Team Name or Game ID'
          variant="outlined" 
          value={searchText}
          onChange={(evt) => {setSearchText(evt.target.value)}}
          InputProps={{
            endAdornment: <InputAdornment style={{cursor: 'pointer'}} position="start" onClick={() => setSearchText('')}>✖</InputAdornment>,
          }}
        />
        {
          !allGamesLoading && <Button
            color='success'
            variant="outlined"
            onClick={handleDownloadAll}
          >
            Download All
          </Button>
        }
      </div>

      

      {
        showGameModal &&
        <div className='w-100 h-100 d-flex align-items-center justify-content-center' style={{position: 'fixed', zIndex: 999, left: 0, top: 0}}>
          <GameDataExplorer sport={sport} subPage={subPage} gameId={showGameModal} handleClose={() => {setShowGameModal(null)}} getRawGameDataQuery={getRawGameDataQuery}/>
        </div>
      }
    </ThemeProvider>
  )
}